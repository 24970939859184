<template>
  <div class="task_component" :class="{isFilterOpen: checkFilterOpen}">
    <mds-layout-grid>
      <mds-row style="margin: 0">
        <div
          :class="[{ showFilterLeftBlock: showFilterPanel }, 'leftBlock']"
          :style="cssVariables"
        >
          <div>
            <div>
              <h2 class="template-header">Completed Tasks</h2>
            </div>
            <div>
              <div>
                <mds-data-table
                  class="task-table-component"
                  v-if="completedTaskList"
                  show-sortable
                  :header-configs="completedTaskTableHeaders"
                  :row-data="completedTaskList"
                  row-hover
                  @mds-data-table-sort-change="sortColumnsOnClick($event)"
                >
                  <template v-slot:body-cell="cellData">
                    <template v-if="cellData.colIndex === 0">
                      
                        <span class="no-highlight" @click="redirectToApproveScreen(cellData.rowData)">
                            {{cellData.rowData[cellData.headerConfig.fieldName]}}
                        </span>
                    </template>
                    <template v-if="cellData.headerConfig.fieldName == 'completed'">
                        <span v-if="cellData.rowData[cellData.headerConfig.fieldName] != null">
                            {{convertDate(cellData.rowData[cellData.headerConfig.fieldName])}}
                        </span>
                        <span v-else>
                            {{cellData.rowData[cellData.headerConfig.fieldName]}}
                        </span>
                    </template>
                  </template>
                </mds-data-table>
                <div >
                 <mds-empty-state
                    v-if="completedTaskList.length < 1"
                    class="no-results-message"
                    size="medium"
                    title="No results matched"
                    message="We weren't able to match any results with your current selections. Please adjust your filter criteria and try again."
                ></mds-empty-state>
                </div>
                 <!-- <h3 v-if="completedTaskList.length < 1" style="text-align: center;">No data available</h3> -->
                <mds-pagination
                  :class="[
                    showFilterPanel === true
                      ? 'move-pagination-show-info'
                      : '',
                    'pagination-component',
                  ]"
                  v-if="completedTaskList.length > 0"
                  :key="paginationComponentKey"
                  :page="pageNumber"
                  show-items-info
                  show-items-select
                  :total-items="totalListItems"
                  :pageSize="pageSize"
                  :pageSizes="[10, 20, 50]"
                  @mds-pagination-page-changed="paginateTable"
                  style="margin-top:10px"
                ></mds-pagination>
              </div>
            </div>
          </div>
        </div>

        <div :class="[ { showFilterRightBlock: showFilterPanel },'rightBlock',]" :style="cssVariables">
           <transition-group name="fade" class="group-4">
            <filter-panel
              :key="checkBoxFilterKey"
              :filterData="filterData"
              searchInputLable=""
              searchInputPlaceHolder="Search by name"
              :showSearchInput="true"
              :searchInputText="filterPanelSearchInputText"
              :isFilterPanelOpen="showFilterPanel"
              componentName="completedTask"
              :hideFilterPanelProp="hideFilterPanel"
              @showFilterPanel="openFilterPannel"
              @hideFilterPanel="closeFilterPanel"
              @multiSelectFilterChange="multiSelectFilterChange"
              @clearAllFilters="clearAllFilters"
              @dateRange="ondateRangeApplied"
              @filterEvent="searchFilterEvent"
              @clearInputFilter="clearSearchInput"
            ></filter-panel>
          </transition-group>
        </div>
      </mds-row>
    </mds-layout-grid>
    <loader-component v-if="displayLoader"></loader-component>
  </div>
</template>
<script>
import { MdsLayoutGrid, MdsRow } from "@mds/layout-grid";
import { MdsDataTable } from "@mds/data-table";
import loaderComponent from "../../../ui_component/loaderComponent.vue";
import { dateFormatterMixin } from "../../../../mixins/date.js";
import MdsPagination from "@mds/pagination";
import FilterPanel from '../../../common_components/FilterPanel.vue';
import MdsEmptyState from "@mds/empty-state";
export default {
  mixins: [dateFormatterMixin],
  components: {
    MdsLayoutGrid,
    MdsRow,
    loaderComponent,
    MdsDataTable,
    MdsPagination,
    FilterPanel,
    MdsEmptyState
  },
  props: {
    pageNumber:{
        default:1
    },
    pageSize:{
        default:10
    },
    totalItems:{
        default:0
    },
    menuwidth: {
      type: String,
    },
    displayLoader: {
      type: Boolean,
      default: false,
    },
    assignedToYouList: {
      type: Array,
    },
    assignedToYouTableHeaders: {
      type: Array,
    },
    completedTaskTableHeaders: {
      type: Array,
    },
    completedTaskList: {
      type: Array,
    },
    filterData:{
      type: Array,
    },
    paginationComponentKey: {
            type: Number
        }
  },
  data() {
    return {
      checkBoxFilterKey:0,
      paginationKeyComponent:0,
      filterPanelSearchInputText: "",
      isAssignedToYouTabActive: true,
      isCompletedTabActive: false,
      showFilterPanel: false,
      hideFilterPanel: true,
      checkFilterOpen: false,
      tabItems: [
        {
          text: "Assigned to You (21)",
          id: "Assigned to You",
          active: true,
        },
        {
          text: "Completed",
          id: "Completed",
          active: false,
        },
      ],
     
      // SearchParameters:[
      //     {
      //         "SearchColumnNames": ["CompletedBy"],
      //         "ComparisionType": 0,
      //         "SearchValues":[]
      //     },
      //     {
      //         "SearchColumnNames": ["workflow"],
      //         "ComparisionType": 0,
      //         "SearchValues":[]
      //     },
      //     {
      //         "SearchColumnNames": ["LastCompletedStep"],
      //         "ComparisionType": 0,
      //         "SearchValues":[]
      //     }
      // ]
    };
  },
  computed: {
    cssVariables() {
      return {
        "--menuWidth": this.menuwidth,
      };
    },
    totalListItems() {
      return this.totalItems;
    },
  },
  methods: {
    closeFilterPanel() {
      this.checkFilterOpen = false;
      this.showFilterPanel = false;
    },
    openFilterPannel() {
      this.checkFilterOpen = true;
      this.showFilterPanel = true;
    },
    getRouteDetails() {
      const routeDetails = this.$router.resolve({
        path: '/approve',
      });
      return routeDetails.resolved.name;
    },
    colorCode(healthStatus) {
      if (healthStatus === "On Track" || healthStatus === "Good") {
        return "#00AF41";
      } else if (healthStatus === "Due Soon" || healthStatus === "Fair") {
        return "#F5C400";
      } else {
        return "#FF0000";
      }
    },
    sortColumnsOnClick(event) {
      this.$emit("sortColumns", event);
      this.paginationKeyComponent++
    },
    async paginateTable(arg) {
      this.$emit("onPaginationChange",arg)
      this.paginationKeyComponent++
    },
    async multiSelectFilterChange(event,value,headerTypeDetails){
      this.$emit("onmultiSelectFilterChange", event, value, headerTypeDetails);
    },
    async clearAllFilters(){
      this.$emit("clearAllFilters")
    },
    ondateRangeApplied(fromDate, toDate){
      this.$emit("dateRangeChange",fromDate, toDate)
    },
    searchFilterEvent(searchValue){
      this.$emit("filterEvent",searchValue)
    },
    clearSearchInput(){
      this.$emit("clearSearchInputField")
    },
    redirectToApproveScreen(value){
      this.$router.push(`/approve?id=${value.id}&task=${'completed'}`);
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../../../assets/css/styles.scss";

.isFilterOpen {width: 82%}
.leftBlock {
  max-width: 94%;
  position: relative;
  overflow: hidden;
  margin-left: 16px;
  margin-right: 71px;
  width: -webkit-fill-available;
}
.rightBlock {
  width: 55px;
  padding: 0;
  margin-left: calc(100vw - 55px - var(--menuWidth));
  min-height: 100vh;
  border: 1px solid #979797;
  border-top: 1px solid #cccccc;
  position: fixed;
  z-index: 1;
  background-color: white;
}
.showFilterLeftBlock {
  max-width: 94%;
  margin-left: 16px;
  margin-right: 71px;
}
.showFilterRightBlock {
  width: 292px;
  margin-left: calc(100vw - 292px - var(--menuWidth));
  position: fixed;
  z-index: 1;
}
.no-results-message {
    margin: auto;
    min-width: 50%;
    margin-top: 16px;
}
.template-header {
  @include mds-level-2-heading();
  margin: 16px 0px 20px 0px;
}
.task_component .mds-tabs___VueMDS3Demo {
  margin-bottom: 16px;
}
.link {
  @include mds-link($in-table: true);
}

html {
  @media screen and (min-width: 1280px) {
    overflow-x: hidden;
  }
}
.task-table-component {
  max-height: 560px;
  overflow: auto;
}
.no-highlight {
  color: black;
}
.move-pagination-show-info::v-deep .mds-pagination__show-items___VueMDS3Demo {
  margin-right: calc(292px - 55px);
}
.pagination-component {
    position: fixed;
    width: calc(100vw - 88px - var(--menuWidth));
    bottom: 0;
}
</style>
