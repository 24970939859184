<template>
    <div>
        <div>
            <task-completed  
            :menuwidth="menuwidth" 
            :displayLoader="displayLoader" 
            :completedTaskList='completedTaskData' 
            :completedTaskTableHeaders="completedTaskTableHeaders"
            :pageNumber="paginationPageNumber"
            :pageSize="paginationpageSize"
            :paginationComponentKey="paginationComponentKey"
            :totalItems="paginationtotalItems"
            :filterData="filterObject"
            @sortColumns="sortColumns"
            @onPaginationChange="onPaginationChange"
            @onmultiSelectFilterChange="onmultiSelectFilterChange"
            @dateRangeChange="onDateRangeChangesApplied"
            @clearAllFilters="onClearAllFilters"
            @filterEvent="searchTextFilterEvent"
            @clearSearchInputField="onSearchInputFieldClear"
            ></task-completed>
            <notification-component
             v-if="showNotification"
            :notificationMessage="notificationMessage"
            :notificationType="notificationType"
            :keyType="notificationKey"
            :dismissDelay="5000"
            @close="showNotification = false"
            ></notification-component>
        </div>
        
    </div>
</template>
<script> 
import store from "@/store";
import get from "lodash/get";
import { mapState,mapGetters } from "vuex";
import { TASK_ACTIONS } from "@/store/modules/tasks/contants";
import TaskCompleted from '../components/TaskCompleted.vue';
import NotificationComponent from '../../../ui_component/notificationComponent.vue';
import { REPORTING_ACTIONS } from "@/store/modules/reporting/constants";

export default {
    components:{
        TaskCompleted,
        NotificationComponent
    },
    props: {
        menuwidth: {
            type: String,
        },
    },
    beforeCreate() {
        this.$store = store;
    },
    data(){
        return{
            showNotification: false,
            notificationMessage: "",
            notificationType: "",
            onPageLoad:true,
            searchTextField:'',
            selectedFromDate:"",
            selectedToDate:"",
            sortOrderColumnName:"completed",
            sortOrderIsAscending:false,
            sortOrder:-1,
            sortedColName:'completed',
            paginationPageNumber:1,
            paginationComponentKey:0,
            paginationpageSize:10,
            paginationtotalItems:0,
            displayLoader:false,
            showLoader:false,
            completedTaskData:[],
            completedTaskTableHeaders: [
                    {
                    fieldName: "workflow",
                    text: "Workflow",
                    align: "left",
                    sortable: true,
                    },
                    {
                    fieldName: "lastCompletedStep",
                    text: "Last Completed Step",
                    align: "left",
                    sortable: true,
                    },
                    {
                    fieldName: "entity",
                    text: "Entity",
                    align: "left",
                    sortable: true,
                    },
                    {
                    fieldName: "completed",
                    text: "Completed (UTC)",
                    align: "left",
                    sortable: true,
                    sorted: -1,
                    },
                    {
                    fieldName: "completedBy",
                    text: "Completed By",
                    align: "left",
                    sortable: true,
                    },
                ],
            completedTaskPayload:{
                "Page": 1,
                "PageSize": 10,
                "OrderByColumnName": "completed",
                "IsAscending": false,
                "PaginationSearchParameters":[
                    {
                        "SearchColumnNames": [ "CompletedBy" ],
                        "ComparisionType": 0,
                        "SearchValues":[]
                    },
                    {
                        "SearchColumnNames": ["workflow" ],
                        "ComparisionType": 0,
                        "SearchValues":[]
                    },
                    {
                        "SearchColumnNames": ["LastCompletedStep" ],
                        "ComparisionType": 0,
                        "SearchValues":[]
                    },
                    {
                        "SearchColumnNames": ["Completed"],
                        "ComparisionType": 3,
                        "SearchValues":[]
                    },
                     {
                        "SearchColumnNames": ["entity" ],
                        "ComparisionType": 0,
                        "SearchValues": []
                    } 
                ]
            },
             filterObject:[
        {
        filterHeader:"Workflow",
        selectionType:"multiple",
        showSearchInputField:false,
        searchText:'',
        filterList:[]
        },{
        filterHeader:"Last Completed Step",
        selectionType:"multiple",
        showSearchInputField:false,
        searchText:'',
        filterList:[]
        },
        {
          filterHeader: "Completed Date Range",
          selectionType: "daterange",
          filterList: [],
        },
        {
        filterHeader:"Completed By",
        selectionType:"multiple",
        showSearchInputField:false,
        searchText:'',
        filterList:[]
        }
      ],
        }
    },
    watch:{
        showLoader(val){
            this.displayLoader=val
        },
        selectedFromDate(){
            this.onFromDateChange()
        },
        selectedToDate(){
            this.onToDateChange()
        }
    },
    computed:{
        ...mapGetters(["cachedDispatch"]),
        ...mapState({
            completedTaskList(state) {
                return get(state, "tasks.completedTaskDetails.completedTaskDetails", []);
            },
            completedTaskListLoader(state) {
                return get(state, "tasks.completedTaskDetails.__loading__", false);
            },
            completedTaskListError(state) {
                return get(state, "tasks.completedTaskDetails.__error__", null);
            },
             completedTaskWorkFlowList(state) {
                return get(state, "tasks.completedTaskWorkFlows.completedTaskWorkFlows", []);
            },
            completedTaskWorkFlowListLoader(state) {
                return get(state, "tasks.completedTaskWorkFlows.__loading__", false);
            },
            completedTaskWorkFlowListError(state) {
                return get(state, "tasks.completedTaskWorkFlows.__error__", null);
            },
             completedTaskWorkFlowStepList(state) {
                return get(state, "tasks.completedTaskWorkFlowSteps.completedTaskWorkFlowSteps", []);
            },
            completedTaskWorkFlowStepListLoader(state) {
                return get(state, "tasks.completedTaskWorkFlowSteps.__loading__", false);
            },
            completedTaskWorkFlowStepListError(state) {
                return get(state, "tasks.completedTaskWorkFlowSteps.__error__", null);
            },
            getAllUserList(state) {
                return get(
                    state,
                    "reportingModule.userList.userList",
                    []
                );
            },
        }),
        
    },
    async created(){
        this.getDateRangeFilter();
    },
    async mounted() {
        this.showLoader=true
        this.onPageLoad=false
        await this.completedTaskListFetch()
        await this.fetchWorkFlowList();
        await this.fetchWorkflowStepList()
        await this.fetchWorkflowAllUsersList()
        this.showLoader=false
    },
     methods: {
         getDateRangeFilter(){
          
            this.filterObject[2].filterHeader = "Completed Date Range";
            this.filterObject[2].selectionType = "daterange";
            const selctedFromDate = this.selectedFromDate ? new Date(this.selectedFromDate) : this.selectedFromDate;
            const selctedToDate = this.selectedToDate ? new Date(this.selectedToDate) : this.selectedToDate
            
              this.filterObject[2].filterList = [
                {
                    id: 1,
                    isdisabled: false,
                    lable: "from date",
                    value: selctedFromDate,
                    minMaxDates: {
                        min: new Date(2000, 0, 1),
                        max: new Date(2049, 11, 31),
                    },
                },
                {
                    id: 2,
                    isdisabled: false,
                    lable: "to date",
                    value: selctedToDate,
                    minMaxDates: {
                        min: new Date(2000, 0, 1),
                        max: new Date(2049, 11, 31),
                    },
                },
            ]
        },


         onFromDateChange(){
            for(let i=0;i<this.filterObject.length;i++){
                if(this.filterObject[i].filterHeader == "Completed Date Range"){
                    const selctedFromDate = this.selectedFromDate ? new Date(this.selectedFromDate) : this.selectedFromDate;
                    this.filterObject[i].filterList[0].value=selctedFromDate
                }
            }
         },
         onToDateChange(){
             for(let i=0;i<this.filterObject.length;i++){
                if(this.filterObject[i].filterHeader == "Completed Date Range"){
                     const selctedToDate = this.selectedToDate ? new Date(this.selectedToDate) : this.selectedToDate
                    this.filterObject[i].filterList[1].value=selctedToDate
                }
            }

         },
         async fetchWorkFlowList(){
             await this.cachedDispatch(TASK_ACTIONS.FETCH_COMPLETEDTASK_WORKFLOWS)
             for(let i=0;i<this.filterObject.length;i++){
                 if(this.filterObject[i].filterHeader == "Workflow"){
                     for(let j=0;j<this.completedTaskWorkFlowList.length;j++){
                         let workFlowObject={
                                lable: this.completedTaskWorkFlowList[j].workflowname,
                                id: this.completedTaskWorkFlowList[j].id,
                                isChecked: false,
                            }
                            this.filterObject[i].filterList.push(workFlowObject)
                     }
                 }
             }
             
         },
        async fetchWorkflowStepList(){
             await this.cachedDispatch(TASK_ACTIONS.FETCH_COMPLETEDTASK_WORKFLOW_STEP)
             for(let i=0;i<this.filterObject.length;i++){
                 if(this.filterObject[i].filterHeader == "Last Completed Step"){
                     for(let j=0;j<this.completedTaskWorkFlowStepList.length;j++){
                         let workFlowObject={
                                lable: this.completedTaskWorkFlowStepList[j].stepName,
                                id: j,
                                isChecked: false,
                            }
                            this.filterObject[i].filterList.push(workFlowObject)
                     }
                 }
             }
         },
         async fetchWorkflowAllUsersList(){
             await this.cachedDispatch(REPORTING_ACTIONS.FETCH_USER_LIST);
            let response = this.getAllUserList.data;
            for(let i=0;i<this.filterObject.length;i++){
                 if(this.filterObject[i].filterHeader == "Completed By"){
                     for(let j=0;j<response.length;j++){
                         let workFlowObject={
                                lable: response[j].email,
                                id: j,
                                isChecked: false,
                            }
                            this.filterObject[i].filterList.push(workFlowObject)
                     }
                 }
             }
         },
        // common function
        async completedTaskListFetch() {
            let data = this.completedTaskPayload
            data.Page=this.paginationPageNumber
            data.PageSize=this.paginationpageSize
            data.OrderByColumnName=this.sortOrderColumnName
            data.IsAscending=this.sortOrderIsAscending
            if(this.selectedFromDate && this.selectedToDate){
                for(let i=0;i<data.PaginationSearchParameters.length;i++){
                    if(data.PaginationSearchParameters[i].SearchColumnNames[0] == "Completed"){
                        data.PaginationSearchParameters[i].ComparisionType=3
                        
                        let temp = this.selectedFromDate.split("/");
                        if (temp[0].length == 1) {
                            temp[0] = "0" + temp[0];
                        }
                        if (temp[1].length == 1) {
                            temp[1] = "0" + temp[1];
                        }
                        let fromdate = temp[2] + "-" + temp[0] + "-" + temp[1];

                        let temp1 = this.selectedToDate.split("/");
                        if (temp1[0].length == 1) {
                            temp1[0] = "0" + temp1[0];
                        }
                        if (temp1[1].length == 1) {
                            temp1[1] = "0" + temp1[1];
                        }
                        let todate = temp1[2] + "-" + temp1[0] + "-" + temp1[1];
                        data.PaginationSearchParameters[i].SearchValues=[]
                        data.PaginationSearchParameters[i].SearchValues.push(fromdate+"T00:00:00.000")
                        data.PaginationSearchParameters[i].SearchValues.push(todate+"T23:59:59.000")
                    }
                }
            }
            if(this.searchTextField){
                for(let i=0;i<data.PaginationSearchParameters.length;i++){
                    if(data.PaginationSearchParameters[i].SearchColumnNames[0] == "entity"){
                        data.PaginationSearchParameters[i].SearchValues=[]
                        data.PaginationSearchParameters[i].SearchValues.push(this.searchTextField)
                        data.PaginationSearchParameters[i].ComparisionType=1
                    }
                }
            }
            await this.$store.dispatch(TASK_ACTIONS.FETCH_COMPLETEDTASK_LIST,{
                        data,
                    })
            if(this.completedTaskListError == null){
                await this.formatcompletedTaskListData()
                this.paginationPageNumber=this.completedTaskList.pageNumber
                this.paginationpageSize=this.completedTaskList.pageSize
                this.paginationtotalItems=this.completedTaskList.totalItems
            }else{
                this.showLoader=false
                let response = this.completedTaskListError
                 const obj = {
                ntfnMsg: response.data,
                ntfnType: "error",
                ntfnKey: "error-default",
            };
            this.notificationResponse(obj);
            }
            
        },
        notificationResponse({
            ntfnMsg,
            ntfnType,
            ntfnKey,
        }) {
                this.showLoader = false;
                this.notificationKey = ntfnKey;
                this.notificationMessage = ntfnMsg;
                this.notificationType = ntfnType;
                this.showNotification = true;
        },

        formatcompletedTaskListData(){
            
             let response = this.completedTaskList.list
             this.completedTaskData=[]
             if (response !== undefined) {
                response.forEach((item) => {
                let data = item;
                if (data !== null) {
                    data.id = item.fdmpWorkflowOpentaskId;
                    this.completedTaskData.push(data);
                }
                });
            }
        },
       async onPaginationChange(arg){
            this.showLoader=true
            this.paginationPageNumber=arg.page
            this.paginationpageSize=arg.pageSize
            this.paginationComponentKey++
            await this.completedTaskListFetch()
            this.showLoader=false
        },
         /**This logic given in MDS documentaion so added in seperate function */
        executeSortingLogicForMdsTable(colName) {
            // if your tracked sort order is 0 (none), set it 1 (ascending)
            // if there is already an order being tracked inverse it
            this.sortOrder = this.sortOrder === 0 ? 1 : -this.sortOrder;

            // if the table was already sorted by a different column, then reset that column to 0 (none)
            if (this.sortedColName !== null && this.sortedColName !== colName) {
                for(let i=0;i<this.completedTaskTableHeaders.length;i++){
                    if(this.completedTaskTableHeaders[i].fieldName == this.sortedColName){
                        this.completedTaskTableHeaders[i].sorted = 0
                    }
                }
                this.sortOrder = -1;
            }

            // track the current column as the one being sorted on
            this.sortedColName = colName;
            for(let i=0;i<this.completedTaskTableHeaders.length;i++){
                    if(this.completedTaskTableHeaders[i].fieldName == this.sortedColName){
                        this.completedTaskTableHeaders[i].sorted = this.sortOrder
                    }
                }
        },
       async sortColumns(event){
           if(!this.onPageLoad){ 
            this.showLoader=true
            this.paginationPageNumber=1
            this.paginationComponentKey++
            this.sortOrderColumnName=event.sortField
            await this.executeSortingLogicForMdsTable(event.sortField)
            if(this.sortOrder == 1){
                this.sortOrderIsAscending=true
            }else if(this.sortOrder == -1){
                this.sortOrderIsAscending=false
            }
            await this.completedTaskListFetch()
            this.showLoader=false
           }
        },
        async onmultiSelectFilterChange(event,value,headerTypeDetails){
            let header=""
            for(let i = 0; i < this.filterObject.length; i++) {
                if(this.filterObject[i].filterHeader == headerTypeDetails) {
                    for(let j = 0; j < this.filterObject[i].filterList.length; j++) {
                        if(this.filterObject[i].filterList[j].id == value.id) {
                            this.filterObject[i].filterList[j].isChecked = event;
                            break;
                        }
                    }
                }
            }
            if(headerTypeDetails == "Workflow"){
                header="workflow"
            }else if(headerTypeDetails == "Last Completed Step"){
                 header="LastCompletedStep"
            }else if(headerTypeDetails == "Completed By"){
                 header="CompletedBy"
            }
            if(event){
                for(let i=0;i<this.completedTaskPayload.PaginationSearchParameters.length;i++){
                    if(this.completedTaskPayload.PaginationSearchParameters[i].SearchColumnNames[0].toLowerCase() == header.toLowerCase()){
                        
                        this.completedTaskPayload.PaginationSearchParameters[i].SearchValues.push(value.lable)
                    }
                }
            }else{
                for(let i=0;i<this.completedTaskPayload.PaginationSearchParameters.length;i++){
                    if(this.completedTaskPayload.PaginationSearchParameters[i].SearchColumnNames[0].toLowerCase() == header.toLowerCase()){
                        if(this.completedTaskPayload.PaginationSearchParameters[i].SearchValues.length != 0){
                            for(let j=0;j<this.completedTaskPayload.PaginationSearchParameters[i].SearchValues.length;j++){
                                if(this.completedTaskPayload.PaginationSearchParameters[i].SearchValues[j].toLowerCase() == value.lable.toLowerCase()){
                                    this.completedTaskPayload.PaginationSearchParameters[i].SearchValues.splice(j,1)
                                    break;
                                }
                            }
                        }
                    }
                }
            }
            this.paginationPageNumber=1
            this.paginationComponentKey++
            this.showLoader=true
            await this.completedTaskListFetch();
            this.showLoader=false
        },
       async onDateRangeChangesApplied(fromDate,toDate){
            this.selectedFromDate=fromDate.toLocaleDateString();
            this.selectedToDate=toDate.toLocaleDateString();
            this.paginationPageNumber=1
            this.paginationComponentKey++
            this.showLoader=true
            await this.completedTaskListFetch();
            this.showLoader=false
        },
        async onClearAllFilters(){
            if(this.selectedFromDate == null){
                this.selectedFromDate=''
            }else{
                this.selectedFromDate=null
            }
            if(this.selectedToDate == null){
                this.selectedToDate=''
            }else{
                this.selectedToDate=null
            }
             this.searchTextField=""
            for(let i=0;i<this.completedTaskPayload.PaginationSearchParameters.length;i++){
                this.completedTaskPayload.PaginationSearchParameters[i].SearchValues=[]
                if(this.completedTaskPayload.PaginationSearchParameters[i].SearchColumnNames[0] == "Completed"){
                    this.completedTaskPayload.PaginationSearchParameters[i].ComparisionType=3
                }else{
                    this.completedTaskPayload.PaginationSearchParameters[i].ComparisionType=0
                }
                
            }
             for(let i=0;i<this.filterObject.length;i++){
                if(this.filterObject[i].selectionType == "multiple"){
                    for(let j=0;j<this.filterObject[i].filterList.length;j++){
                            this.filterObject[i].filterList[j].isChecked = false
                        
                    }
                    
                }
            }
            this.showLoader=true
            this.paginationPageNumber=1
            this.paginationComponentKey++
            await this.completedTaskListFetch();
            this.showLoader=false
        },
       async searchTextFilterEvent(searchValue){
            if(searchValue){
                this.searchTextField=searchValue
                this.showLoader=true
                this.paginationPageNumber=1
                this.paginationComponentKey++
                await this.completedTaskListFetch();
                this.showLoader=false
            }
        },
        async onSearchInputFieldClear(){
            for(let i=0;i<this.completedTaskPayload.PaginationSearchParameters.length;i++){
                if(this.completedTaskPayload.PaginationSearchParameters[i].SearchColumnNames[0] == "entity"){
                    this.completedTaskPayload.PaginationSearchParameters[i].SearchValues=[]
                }
                this.completedTaskPayload.PaginationSearchParameters[i].ComparisionType=0
            }
            this.searchTextField=""
            this.showLoader=true
            await this.completedTaskListFetch();
            this.showLoader=false
        }
     }
}
</script>